// ====== GLOBAL ======
html {
  scroll-behavior: smooth;
}

// ====== COMMON ELEMENTS ======
#footer {
  background-color: #333;
  color: #aaa;
  font-size: 0.9rem;
  padding-top: 30px;
  padding-bottom: 30px;

  .logo {
    height: 35px;
  }

  h2 {
    padding-top: 10px;
    color: #fff;
    font-size: 1.3rem;
  }
  ul {
    list-style: none;
    padding-left: 0;

    li a {
      color: #ccc;
      line-height: 2;

      &:hover {
        color: #fff;
      }
    }
  }
  address {
    margin-top: 13px;
  }
}

// Extra
.card-presswebs {
  border-width: 1px;
  box-shadow: none;

  .card-header {
    padding-top: 5px;
  }
}
