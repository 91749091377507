// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Packages
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~bootstrap-colorpicker/src/sass/colorpicker';

// Styles
@import 'bootstrap-custom';
@import 'styles';
@import 'front';
@import 'front-features';
