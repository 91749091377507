.front.front-features {
  .navbar {
    background: $white;

    .navbar-nav .nav-link {
      font-weight: bold;
      color: $body-color;

      &:hover {
        color: $black;
      }
    }
  }

  #features-dedicated {
    padding-top: 20px;
    padding-bottom: 80px;
    color: #444;

    .container {
      max-width: 800px;

      .feature {
        margin-bottom: 30px;

        .img-wrapper {
          text-align: center;
          margin-bottom: 30px;

          img {
            height: 144px;

            @include media-breakpoint-down(xs) {
              height: 100px;
            }
          }
        }

        h2 {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }
}
