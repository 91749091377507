// ====== FRONT ======
.front {
  // Global
  .content {
    padding-top: 30px;
    background-color: #fff;
  }

  a {
    color: $primary;
  }

  .btn {
    color: $white;
    border: none;
  }

  .btn-lg {
    padding: 0.6rem 2rem;
    font-weight: bold;
  }

  // ELements
  .logo {
    height: 35px;
  }

  .navbar {
    background: transparent linear-gradient(to bottom, #000, rgba(255, 255, 255, 0));
    box-shadow: none;

    .navbar-nav {
      a.nav-link {
        color: #efe9df;
        font-weight: 500;
        padding-right: 1rem;
        padding-left: 1rem;

        &:hover {
          color: $white;
        }
      }
    }
  }

  h1 {
    font-size: 2.2rem;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .description {
    text-align: center;
    color: #999;
    font-size: 1.3rem;
    margin-bottom: 70px;
  }

  #splash {
    margin-top: -91px;
    padding-top: 160px;
    padding-bottom: 60px;
    min-height: 500px;
    color: $white;
    text-align: center;

    h1 {
      font-size: 3.4rem;
      color: $white;
    }

    .description {
      font-size: 1.8rem;
      color: $white;
      margin-bottom: 90px;
    }

    .btn {
      margin: 10px;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 110px;
      padding-bottom: 30px;
      min-height: 350px;

      h1 {
        font-size: 2.6rem;
      }

      .description {
        font-size: 1.5rem;
        margin-bottom: 50px;
      }
    }

    @include media-breakpoint-down(md) {
      .description {
        font-size: 1.3rem;
      }
    }
  }

  #features {
    padding-top: 80px;
    padding-bottom: 80px;
    color: #444;

    .feature {
      .img-wrapper {
        text-align: center;
        margin-bottom: 30px;

        img {
          height: 100px;
        }
      }

      h2 {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }

  #callout {
    padding-top: 70px;
    padding-bottom: 70px;
    background: #d4e0ea;
    color: #122331;
    border-bottom: 13px solid #dee8f1;

    h1 {
      text-align: left;
    }

    .description {
      font-size: 1rem;
      color: inherit;
      text-align: left;
      margin-bottom: 1rem;
    }

    .client-name {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 0;
    }

    .client-description {
      margin-bottom: 0;
    }

    .client-link {
    }

    blockquote {
      border-left: 3px solid #122331;
      padding-left: 20px;

      p {
        font-size: 1rem;
        font-style: italic;
      }

      footer {
        margin-top: 1rem;
        color: #333;
      }
    }
  }

  #features-more {
    padding-top: 80px;
    padding-bottom: 80px;
    color: #444;

    .feature {
      margin-bottom: 60px;

      .img-wrapper {
        text-align: center;
        margin-bottom: 20px;

        img {
          height: 100px;
        }
      }

      h2 {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }

  #plans {
    padding-top: 80px;
    padding-bottom: 80px;
    border-top: 1px solid #a9d1e8;
    background: linear-gradient(to bottom, #e6f1f9, #fff);

    .description {
      color: #444;
      margin-bottom: 30px;
    }

    .card {
      border-color: #a20606;
      text-align: center;

      .card-header {
        background-color: #a20606;
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .price {
        color: #018000;
        font-size: 3rem;
        font-weight: bold;
        padding-top: 0.5rem;
        padding-bottom: 0;
      }

      .dollar {
        font-size: 0.5em;
        top: -0.5em;
        position: relative;
      }

      .decimals {
        font-size: 0.5em;
        top: -0.6em;
        position: relative;
        font-weight: normal;
      }

      .period {
        font-size: 1rem;
        font-weight: normal;
        margin-top: -12px;
      }

      .features {
        padding-top: 2px;
        padding-bottom: 0;

        ul {
          list-style: none;
          padding: 2px;
          margin: 0;

          li {
            line-height: 1.8;
            border-bottom: 1px solid #eee;
            color: #555;
          }

          li:last-child {
            border: none;
          }
        }
      }

      .order {
        .btn {
          font-size: 1rem;
        }
      }
    }

    hr {
      width: 50%;
      margin: 60px auto 30px;
      border-color: #bbb;
    }

    .guarantee {
      color: #444;
      text-align: center;

      .img-wrapper {
        text-align: center;
        margin-bottom: 20px;

        img {
          height: 80px;
        }
      }

      h2 {
        font-size: 1.1rem;
      }
    }
  }

  #questions {
    padding-bottom: 100px;
    text-align: center;

    h1 {
      padding-top: 80px;
      margin-bottom: 20px;
    }
  }
  
  #technology-partners {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: hsl(0, 0%, 97%);
    text-align: center;

    h1 {
      font-size: 1.3rem;
    }

    img {
      filter: grayscale(100%) opacity(70%);
      max-height: 60px;

      &:hover {
        filter: none;
      }
    }

    @include media-breakpoint-up(lg) {
      .col {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}
