// ====== Global Bootstrap components
// Navbar
.navbar {
  background: $white;
  box-shadow: 0 1px 9px #9daebb;
  padding-top: 3px;
  padding-bottom: 3px;

  .navbar-nav .nav-link {
    font-weight: bold;
    color: $body-color;

    &:hover {
      color: $black;
    }
  }

  // Make dropdown open on hover
  .dropdown {
    .dropdown-menu {
      display: block;
      opacity: 0;
      pointer-events: none;
      top: 90%; // Move the menu up a bit to cover non-hover area that would inadvertently make the menu to disappear

      .dropdown-item {
        transition: background-color 0.1s linear, border 0.1s linear;
        border-left: 5px solid transparent;

        &:first-child {
          border-radius: 0;
        }

        &:hover, &:focus {
          border-left: 5px solid $primary;
        }
      }
    }

    &:hover {
      .dropdown-menu {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }

  .logo {
    height: 35px;
  }
}

// Beautiful nav tabs
.nav-tabs {
  margin-bottom: 20px;

  .nav-link.active, .nav-item.show .nav-link {
    color: $primary;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $primary;
  }

  .nav-link {
    border: none;
    color: #666;
    font-weight: bold;

    &:hover, &:focus {
      color: $primary;
    }
  }
}

// Card
.card {
  // box-shadow: 0 1px 9px rgb(198, 218, 233);
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}
